import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL } from "@/const";
export default {
  data() {
    return {
      validate: {
        // 企業情報
        companyInfo: {
          contractName: { isValid: true, errMsg: "" },
          zipcode: { isValid: true, errMsg: "" },
          prefecture: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          building: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          fax: { isValid: true, errMsg: "" },
          selectGroup: { isValid: true, errMsg: "" },
          legalPersonalityTypeId: { isValid: true, errMsg: "" },
          contractGroupName: { isValid: true, errMsg: "" },
        },
        contractInfo: {
          contractType: { isValid: true, errMsg: "" },
          startDate: { isValid: true, errMsg: "" },
          endDate: { isValid: true, errMsg: "" },
          invoiceMethod: { isValid: true, errMsg: "" },
          invoiceTiming: { isValid: true, errMsg: "" },
          zipcodeFirst: { isValid: true, errMsg: "" },
          zipcodeSecond: { isValid: true, errMsg: "" },
          prefecture: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          building: { isValid: true, errMsg: "" },
          staffDepartment: { isValid: true, errMsg: "" },
          staffMail: { isValid: true, errMsg: "" },
          staffName: { isValid: true, errMsg: "" },
        },
        accountInfo: {
          department: { isValid: true, errMsg: "" },
          name: { isValid: true, errMsg: "" },
          mail: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          mobile: { isValid: true, errMsg: "" },
        },
      },

      isValidForm: {
        companyInfo: true,
        contractInfo: true,
      },
    };
  },
  methods: {
    async check(formEdit, selectedGroupOptVal) {
      const vcompany = this.validate.companyInfo;
      const vcontract = this.validate.contractInfo;
      const vaccount = this.validate.accountInfo;
      const numberRegexp = new RegExp(/^[0-9]*$/);
      const mailRegexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
      let isValidForm = true;

      // 会社名/営業所名の必須と桁数チェック
      if (!vcompany.contractName.isValid) {
        vcompany.contractName.errMsg = "会社名/営業所名は必須入力です。";
        isValidForm = false;
      }
      if (formEdit.contractName.length > 100) {
        vcompany.contractName.errMsg = "会社名/営業所名は100桁以内で入力してください。";
        isValidForm = false;
      }
      // 企業情報の住所
      // 郵便番号の必須と桁数チェック
      if (!vcompany.zipcode.isValid) {
        vcompany.zipcode.errMsg = "郵便番号は必須入力です。";
        isValidForm = false;
      } else {
        const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${formEdit.addressInfo.zipCode}`;
        await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (vcompany.prefecture.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (formEdit.addressInfo.prefecturesName === address.address1) {
                      prefectureFlg = true;
                    }
                  });
                  if (!prefectureFlg) {
                    vcompany.prefecture.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (vcompany.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (formEdit.addressInfo.address === address.address2) {
                      addressFlg = true;
                    }
                  });
                  if (!addressFlg) {
                    vcompany.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }
      if (formEdit.addressInfo.zipCode.length > 7) {
        vcompany.zipCode.errMsg = "郵便番号は7桁以内で入力してください。";
        isValidForm = false;
      }
      // 都道府県の必須チェック
      if (!vcompany.prefecture.isValid) {
        vcompany.prefecture.errMsg = "都道府県は必須です。";
        isValidForm = false;
      }
      // 市区町村番地の必須と桁数チェック
      if (!vcompany.address.isValid) {
        vcompany.address.errMsg = "市区町村番地は必須入力です。";
        isValidForm = false;
      }
      if (formEdit.addressInfo.address.length > 100) {
        vcompany.address.errMsg = "市区町村番地は100桁以内で入力してください。";
        isValidForm = false;
      }
      // 電話番号の必須と文字種(数値)、桁数チェック
      if (!vcompany.tel.isValid) {
        vcompany.tel.errMsg = "電話番号は必須入力です。";
        isValidForm = false;
      }
      if (!numberRegexp.test(formEdit.tel)) {
        vcompany.tel.errMsg = "電話番号は数字で入力してください。";
        isValidForm = false;
      }
      if (formEdit.tel.length > 15) {
        vcompany.tel.errMsg = "電話番号は15桁以内で入力してください。";
        isValidForm = false;
      }
      // Fax番号の桁数と文字種(数値)チェック
      if (formEdit.fax && formEdit.fax.length > 15) {
        vcompany.fax.errMsg = "FAX番号は15桁以内で入力してください。";
        isValidForm = false;
      }
      if (formEdit.fax && !numberRegexp.test(formEdit.fax)) {
        vcompany.fax.errMsg = "電話番号は数字で入力してください。";
        isValidForm = false;
      }
      // 企業グループ(新規グループを作成 選択時)
      if (selectedGroupOptVal === 'create-new') {
        // 法人種別の必須チェック
        if (!vcompany.legalPersonalityTypeId.isValid) {
          vcompany.legalPersonalityTypeId.errMsg = "法人種別は必須入力です。"
          isValidForm = false;
        }
        // 企業グループ名の必須と桁数チェック
        if (!vcompany.contractGroupName.isValid) {
          vcompany.contractGroupName.errMsg = "企業グループ名は必須入力です。";
          isValidForm = false;
        }
        if (formEdit.generateContractGroupInfo.contractGroupName.length > 100) {
          vcompany.contractGroupName.errMsg = "企業グループ名は100桁以内で入力してください。";
          isValidForm = false;
        }
      }
      // 契約プランの必須チェック
      if (!vcontract.contractType.isValid) {
        vcontract.contractType.errMsg = "契約プランは必須です。";
        isValidForm = false;
      }
      // 契約開始日の必須チェック
      if (!vcontract.startDate.isValid) {
        vcontract.startDate.errMsg = "契約開始日は必須入力です。";
        isValidForm = false;
      }
      // 契約終了日の必須チェック
      if (!vcontract.endDate.isValid) {
        vcontract.endDate.errMsg = "契約終了日は必須入力です。";
        isValidForm = false;
      }
      // 契約自動更新の必須チェック
      if (formEdit.isAutoUpdate == null) {
        isValidForm = false;
      }
      // 割引キャンペーン対象の必須チェック
      if (formEdit.isDiscountCampaign == null) {
        isValidForm = false;
      }
      // 請求方法の必須チェック
      if (!vcontract.invoiceMethod.isValid) {
        vcontract.invoiceMethod.errMsg = "請求方法は必須です。";
        isValidForm = false;
      }
      // 請求タイミングの必須チェック
      if (!vcontract.invoiceTiming.isValid) {
        vcontract.invoiceTiming.errMsg = "請求タイミングは必須です。";
        isValidForm = false;
      }
      // 請求先の必須チェック
      if (formEdit.isSameAddress == null) {
        isValidForm = false;
      }
      // 請求先(別の住所 選択時)
      if (!formEdit.isSameAddress) {
        // 郵便番号の必須と桁数チェック
        if (!vcontract.zipcodeFirst.isValid) {
          vcontract.zipcodeFirst.errMsg = "郵便番号は必須入力です。";
          isValidForm = false;
        }
        if (formEdit.invoiceAddressInfo.zipCode.length > 7) {
          vcontract.zipcodeFirst.errMsg = "郵便番号は7桁以内で入力してください。";
          isValidForm = false;
        }
        // 都道府県の必須チェック
        if (!vcontract.prefecture.isValid) {
          vcontract.prefecture.errMsg = "都道府県は必須です。";
          isValidForm = false;
        }
        // 市区町村番地の必須と桁数チェック
        if (!vcontract.address.isValid) {
          vcontract.address.errMsg = "市区町村番地は必須入力です。";
          isValidForm = false;
        }
        if (formEdit.invoiceAddressInfo.address.length > 100) {
          vcontract.address.errMsg = "市区町村番地は100桁以内で入力してください。";
          isValidForm = false;
        }
      }
      // 担当者部署の必須と桁数チェック
      if (!vcontract.staffDepartment.isValid) {
        vcontract.staffDepartment.errMsg = "担当者部署は必須入力です。";
        isValidForm = false;
      }
      if (formEdit.contractStaffDepartment.length > 50) {
        vcontract.staffDepartment.errMsg = "担当者部署は50桁以内で入力してください。";
        isValidForm = false;
      }
      // 担当者名の必須と桁数チェック
      if (!vcontract.staffName.isValid) {
        vcontract.staffName.errMsg = "担当者名は必須入力です。";
        isValidForm = false;
      }
      if (formEdit.contractStaffName.length > 50) {
        vcontract.staffName.errMsg = "担当者名は50桁以内で入力してください。";
        isValidForm = false;
      }
      // 担当者メールアドレスの必須とメールアドレス形式チェック
      if (!vcontract.staffMail.isValid) {
        vcontract.staffMail.errMsg = "担当者メールアドレスは必須入力です。";
        isValidForm = false;
      }
      if (!mailRegexp.test(formEdit.contractStaffMail)) {
        vcontract.staffMail.errMsg = "メールアドレスの形式が正しくありません。正しいメールアドレスを入力してください。";
        isValidForm = false;
      }
      // 部署の必須と桁数チェック
      if (!vaccount.department.isValid) {
        vaccount.department.errMsg = "部署は必須入力です。";
        isValidForm = false;
      }
      if (formEdit.department.length > 50) {
        vaccount.department.errMsg = "部署は50桁以内で入力してください。";
        isValidForm = false;
      }
      // 氏名の必須と桁数チェック
      if (!vaccount.name.isValid) {
        vaccount.name.errMsg = "氏名は必須入力です。";
        isValidForm = false;
      }

      if (!vaccount.name.isValid) {
        vaccount.name.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      // 氏名の文字数の制限: 全角12文字以内のチェック
      if (formEdit.memberName) {
        if (formEdit.memberName.length > 12) {
          vaccount.name.errMsg = "全角12文字以内で入力してください。";
          isValidForm = false;
        }
      }
      // メールアドレスの必須とメールアドレス形式チェック
      if (!vaccount.mail.isValid) {
        vaccount.mail.errMsg = "メールアドレスは必須入力です。";
        isValidForm = false;
      }
      if (!mailRegexp.test(formEdit.memberMail)) {
        vaccount.mail.errMsg = "メールアドレスの形式が正しくありません。正しいメールアドレスを入力してください。";
        isValidForm = false;
      }

      return isValidForm;
    },
    async runValidate(formEdit, selectedGroupOptVal) {
      return await this.check(formEdit, selectedGroupOptVal);
    },
  },
};
