export default {
  data() {
    return {
      formEdit: {
        generateContractGroupId: null,
        generateContractGroupInfo: {
          legalPersonalityTypeId: null,
          contractGroupName: "",
        },
        contractName: "",
        addressInfo: {
          zipCode: "",
          prefecturesId: null,
          address: "",
          buildingName: "",
        },
        tel: "",
        fax: "",
        isHeadquarters: false,
        contractTypeId: null,
        jwnetLineTypeId: null,
        invoiceTypeId: null,
        isSameAddress: true,
        invoiceAddressInfo: {
          zipCode: "",
          prefecturesId: null,
          address: "",
          buildingName: "",
        },
        contractStaffDepartment: "",
        contractStaffName: "",
        contractStaffMail: "",
        remarks: "",
        cbaContractStartDate: "",
        cbaContractEndDate: "",
        isAutoUpdate: true,
        isDiscountCampaign: true,
        invoiceMethodTypeId: null,
        department: "",
        memberName: "",
        memberMail: "",
        memberTel: "",
        memberMobilePhone: "",
        memberId: null,
      },
    };
  },
};
