<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CBA加入編集（排出）" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- 企業情報 ======================================================================== -->
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">企業情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>会社名/営業所名</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText class="lg"
                    :value.sync="formEdit.contractName"
                    inputType="text"
                    placeholder="会社名/営業所名"
                    tooltip="100文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="100"
                    :isValid.sync="validate.companyInfo.contractName.isValid"
                    :errMsg.sync="validate.companyInfo.contractName.errMsg"
                  />
                  <div class="c-checkbox">
                    <input
                      id="isHq-cb"
                      type="checkbox"
                      v-model="formEdit.isHeadquarters"
                    />
                    <label class="c-checkbox__label" for="isHq-cb">
                      <span class="c-checkbox__box"></span>
                      本社
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>郵便番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols center sm-mgn">
                      <InputText
                        :value.sync="formEdit.addressInfo.zipCode"
                        inputType="number"
                        placeholder="1234567(ハイフンなし)"
                        tooltip="7桁の半角数字で入力してください。"
                        :validation="validateNumberEqualLen"
                        :validateArg="7"
                        :isValid.sync="validate.companyInfo.zipcode.isValid"
                        :errMsg.sync="validate.companyInfo.zipcode.errMsg"
                      />
                      <div
                        class="c-btn secondary sm"
                        @click="searchAddressHandler(true, true)"
                      >
                        住所自動入力
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>都道府県</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        class=""
                        name="prefecture"
                        :value.sync="formEdit.addressInfo.prefecturesName"
                        :validation="validateJustBlank"
                        :isValid.sync="validate.companyInfo.prefecture.isValid"
                        :errMsg.sync="validate.companyInfo.prefecture.errMsg"
                      >
                        <option
                          v-for="prefecture in prefectures"
                          :key="prefecture.id"
                          :value="prefecture.name"
                        >
                          {{ prefecture.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>市区町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="formEdit.addressInfo.address"
                        inputType="text"
                        placeholder="市区町村"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="validate.companyInfo.address.isValid"
                        :errMsg.sync="validate.companyInfo.address.errMsg"
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>番地建物名号室</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="formEdit.addressInfo.buildingName"
                        inputType="text"
                        placeholder="番地建物名号室"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="validate.companyInfo.building.isValid"
                        :errMsg.sync="validate.companyInfo.building.errMsg"
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.tel"
                  inputType="number"
                  placeholder="電話番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.companyInfo.tel.isValid"
                  :errMsg.sync="validate.companyInfo.tel.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.fax"
                  inputType="number"
                  placeholder="FAX番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.companyInfo.fax.isValid"
                  :errMsg.sync="validate.companyInfo.fax.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>所属グループ</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <Selectbox
                    class=""
                    name="group1"
                    :value.sync="selectedGroupOptVal"
                    :validation="validateJustBlank"
                    @change="changeGroupHandler(form)"
                  >
                    <option value="none">なし</option>
                    <option value="create-new">新規グループを作る</option>
                    <option value="select-group">既存グループから選択</option>
                  </Selectbox>
                </div>

                <!-- 新規グループを作る選択時表示 -->
                <template v-if="selectedGroupOptVal === 'create-new'">
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>法人種別</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <Selectbox
                        class=""
                        name="group-new"
                        :value.sync="
                          formEdit.generateContractGroupInfo
                            .legalPersonalityTypeId
                        "
                        :validation="validateJustBlank"
                        :isValid.sync="
                          validate.companyInfo.legalPersonalityTypeId.isValid
                        "
                        :errMsg.sync="
                          validate.companyInfo.legalPersonalityTypeId.errMsg
                        "
                      >
                        <option
                          v-for="legalPersonalityType in legalPersonalityTypes"
                          :key="legalPersonalityType.id"
                          :value="legalPersonalityType.id"
                        >
                          {{ legalPersonalityType.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>企業グループ名</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                        :value.sync="
                          formEdit.generateContractGroupInfo.contractGroupName
                        "
                        inputType="text"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="
                          validate.companyInfo.contractGroupName.isValid
                        "
                        :errMsg.sync="
                          validate.companyInfo.contractGroupName.errMsg
                        "
                      />
                    </div>
                  </div>
                </template>

                <!-- 既存グループから選択選択時表示 -->
                <div
                  v-if="selectedGroupOptVal === 'select-group'"
                  class="c-inputWrap"
                >
                  <Selectbox
                    class=""
                    name="group-new"
                    :value.sync="selectedGroup"
                    :validation="validateJustBlank"
                    :change="changeSelectGroupHandler"
                    :isValid.sync="validate.companyInfo.selectGroup.isValid"
                    :errMsg.sync="validate.companyInfo.selectGroup.errMsg"
                  >
                    <option
                      v-for="group in contractGroupList"
                      :key="group.id"
                      :value="group"
                    >
                      {{ group.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>JWNET使用回線</label>
              </div>
              <div class="c-inputWrap__items">
                {{ jwnetLineTypes.find(it => it.value === formEdit.jwnetLineTypeId).name }}
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 契約情報 ======================================================================== -->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">契約情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約プラン</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  name="contract-plan"
                  :value.sync="formEdit.contractTypeId"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contractInfo.contractType.isValid"
                  :errMsg.sync="validate.contractInfo.contractType.errMsg"
                >
                  <option
                    v-for="contractType in contractTypes"
                    :key="contractType.id"
                    :value="contractType.id"
                  >
                    {{ contractType.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約開始日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="formEdit.cbaContractStartDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contractInfo.startDate.isValid"
                  :errMsg.sync="validate.contractInfo.startDate.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="formEdit.cbaContractEndDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contractInfo.endDate.isValid"
                  :errMsg.sync="validate.contractInfo.endDate.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約自動更新</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                      id="auto-update-true"
                      type="radio"
                      name="auto-update"
                      :value="true"
                      v-model="formEdit.isAutoUpdate"
                    />
                    <label class="c-radio__label" for="auto-update-true">
                      <span class="c-radio__box"></span>
                      有
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      id="auto-update-false"
                      type="radio"
                      name="auto-update"
                      :value="false"
                      v-model="formEdit.isAutoUpdate"
                    />
                    <label class="c-radio__label" for="auto-update-false">
                      <span class="c-radio__box"></span>
                      無
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>割引キャンペーン対象</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                      id="discount-true"
                      type="radio"
                      name="discount"
                      :value="true"
                      v-model="formEdit.isDiscountCampaign"
                    />
                    <label class="c-radio__label" for="discount-true">
                      <span class="c-radio__box"></span>
                      有
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      id="discount-false"
                      type="radio"
                      name="discount"
                      :value="false"
                      v-model="formEdit.isDiscountCampaign"
                    />
                    <label class="c-radio__label" for="discount-false">
                      <span class="c-radio__box"></span>
                      無
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>請求方法</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  name="ask-for-timing"
                  placeholder="選択してください"
                  :value.sync="formEdit.invoiceMethodTypeId"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contractInfo.invoiceMethod.isValid"
                  :errMsg.sync="validate.contractInfo.invoiceMethod.errMsg"
                >
                  <option
                    v-for="item in invoiceMethodTypeList"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>請求タイミング</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  name="ask-for-timing"
                  placeholder="選択してください"
                  :value.sync="formEdit.invoiceTypeId"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contractInfo.invoiceTiming.isValid"
                  :errMsg.sync="validate.contractInfo.invoiceTiming.errMsg"
                >
                  <option
                    v-for="invoiceType in invoiceTypes"
                    :key="invoiceType.id"
                    :value="invoiceType.id"
                  >
                    {{ invoiceType.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>請求先</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <div class="c-radio">
                    <input
                      id="same-address"
                      type="radio"
                      name="ask-address"
                      :value="true"
                      v-model="formEdit.isSameAddress"
                      @change="changeInvoiceHandler"
                    />
                    <label class="c-radio__label" for="same-address">
                      <span class="c-radio__box"></span>
                      企業情報と同じ住所
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      id="other-address"
                      type="radio"
                      name="ask-address"
                      :value="false"
                      v-model="formEdit.isSameAddress"
                    />
                    <label class="c-radio__label" for="other-address">
                      <span class="c-radio__box"></span>
                      別の住所
                    </label>
                  </div>
                </div>

                <!-- 別の住所選択じに表示 -->
                <div v-if="!formEdit.isSameAddress" class="c-inputWrap__items">
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>郵便番号</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div class="c-inputWrap__items__cols center sm-mgn">
                        <InputText
                          :value.sync="formEdit.invoiceAddressInfo.zipCode"
                          inputType="number"
                          placeholder="1234567(ハイフンなし)"
                          tooltip="1234567(ハイフンなし)"
                          :validation="validateNumberEqualLen"
                          :validateArg="7"
                          :isValid.sync="
                            validate.contractInfo.zipcodeFirst.isValid
                          "
                          :errMsg.sync="
                            validate.contractInfo.zipcodeFirst.errMsg
                          "
                        />
                        <div
                          class="c-btn secondary sm"
                          @click="searchAddressHandler(false, true)"
                        >
                          住所自動入力
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>都道府県</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div>
                        <Selectbox
                          class=""
                          name="prefecture"
                          :value.sync="
                            formEdit.invoiceAddressInfo.prefecturesName
                          "
                          :validation="validateJustBlank"
                          :isValid.sync="
                            validate.contractInfo.prefecture.isValid
                          "
                          :errMsg.sync="validate.contractInfo.prefecture.errMsg"
                        >
                          <option
                            v-for="prefecture in prefectures"
                            :key="prefecture.id"
                            :value="prefecture.name"
                          >
                            {{ prefecture.name }}
                          </option>
                        </Selectbox>
                      </div>
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>市区町村</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div>
                        <InputText
                          :value.sync="formEdit.invoiceAddressInfo.address"
                          inputType="text"
                          placeholder="市区町村"
                          tooltip="100文字以内で入力してください"
                          :validation="validateMaxLen"
                          :validateArg="100"
                          :isValid.sync="validate.contractInfo.address.isValid"
                          :errMsg.sync="validate.contractInfo.address.errMsg"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>番地建物名号室</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div>
                        <InputText
                          :value.sync="formEdit.invoiceAddressInfo.buildingName"
                          inputType="text"
                          placeholder="番地建物名号室"
                          tooltip="100文字以内で入力してください"
                          :validation="validateMaxLen"
                          :validateArg="100"
                          :isValid.sync="validate.contractInfo.building.isValid"
                          :errMsg.sync="validate.contractInfo.building.errMsg"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                </div>
                <!-- 別の住所選択で展開ここまで -->
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>担当者部署</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.contractStaffDepartment"
                  inputType="text"
                  placeholder="担当者部署"
                  tooltip="50文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  :isValid.sync="validate.contractInfo.staffDepartment.isValid"
                  :errMsg.sync="validate.contractInfo.staffDepartment.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>担当者名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.contractStaffName"
                  inputType="text"
                  placeholder="担当者名"
                  tooltip="50文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  :isValid.sync="validate.contractInfo.staffName.isValid"
                  :errMsg.sync="validate.contractInfo.staffName.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>担当者メールアドレス</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.contractStaffMail"
                  inputType="mail"
                  placeholder="担当者メールアドレス"
                  tooltip="メールアドレスを入力してください"
                  :validation="validateMail"
                  :isValid.sync="validate.contractInfo.staffMail.isValid"
                  :errMsg.sync="validate.contractInfo.staffMail.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="l-area">
                  <MyTextarea
                    :value.sync="formEdit.remarks"
                    rows="6"
                    placeholder="備考を入力してください"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 企業情報 ======================================================================== -->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">管理者アカウント情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>部署</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.department"
                  inputType="text"
                  placeholder="部署"
                  tooltip="50文字以内で入力してください。"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  :isValid.sync="validate.accountInfo.department.isValid"
                  :errMsg.sync="validate.accountInfo.department.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>氏名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.memberName"
                  inputType="text"
                  placeholder="氏名"
                  tooltip="全角12文字以内で入力してください。"
                  :validation="validateMaxLen"
                  :validateArg="12"
                  :isValid.sync="validate.accountInfo.name.isValid"
                  :errMsg.sync="validate.accountInfo.name.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>メールアドレス</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.memberMail"
                  inputType="mail"
                  placeholder="メールアドレス"
                  tooltip="メールアドレスを入力してください。"
                  :validation="validateMail"
                  :isValid.sync="validate.accountInfo.mail.isValid"
                  :errMsg.sync="validate.accountInfo.mail.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.memberTel"
                  inputType="number"
                  placeholder="電話番号"
                  tooltip="半角数値で入力してください。"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.accountInfo.tel.isValid"
                  :errMsg.sync="validate.accountInfo.tel.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号 (携帯)</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.memberMobilePhone"
                  inputType="number"
                  placeholder="電話番号 (携帯)"
                  tooltip="半角数値で入力してください。"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.accountInfo.mobile.isValid"
                  :errMsg.sync="validate.accountInfo.mobile.errMsg"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <div class="l-btns">
          <div class="c-btn secondary small" @click="backHandler">戻る</div>
          <div class="c-btn primary mid" @click="isDisplayNextModal = true">
            次へ
          </div>
        </div>
      </div>
      <!-- /.l-container -->
    </main>

    <!-- 次へ確認モーダル -->
    <div class="c-modal" v-show="isDisplayNextModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          ここまでの内容を保存し、次の入力に進みますか。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isDisplayNextModal = false">戻る</div>
          <div class="c-btn primary small" @click="checkRequiredHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AddressSelectModal
      :isShow.sync="isAddressSelectModalShow"
      :addresses="addressResults"
      @selectAddress="selectAddressHandler"
    />
    <AppFooter />
  </div>
</template>

<script>
import MyTextarea from "@/components/parts/input/MyTextarea";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import AddressSelectModal from "../components/AddressSelectModal.vue";
import validation from "@/mixin/validation";
import formValueEdit from "../mixins/formValueEdit";
import formValue from "../mixins/formValue";
import callApi from "../mixins/callApi";
import groupList from "../mixins/form/groupList"; // グループリスト処理
import addressSearch from "../mixins/form/addressSearch"; // 住所検索処理
import formValidationEdit from "../mixins/form/formValidationEdit";
import {API_URL, JWNET_LINE_TYPE} from "@/const";
import axios from "axios";
import common from "@/mixin/common";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "step1-edit",
  components: {
    ModalFooterButtons,
    MyTextarea,
    InputText,
    Selectbox,
    InputDate,
    AddressSelectModal,
  },
  mixins: [
    validation,
    formValueEdit,
    formValue,
    callApi,
    groupList,
    addressSearch,
    formValidationEdit,
    common,
  ],
  data() {
    return {
      isEdit: true,
      errMsgs: [],
      generateContractId: null,
      isDisplayNextModal: false,
      processing: false,
      jwnetLineTypes: JWNET_LINE_TYPE,
    };
  },
  methods: {
    // 請求先を企業情報と同じにした場合
    changeInvoiceHandler() {
      this.formEdit.invoiceAddressInfo = {
        zipCode: "",
        prefecturesId: null,
        address: "",
        buildingName: "",
      };
    },

    postForm() {
      const postData = JSON.parse(JSON.stringify(this.formEdit));
      if (this.selectedGroupOptVal === "none") {
        delete postData.generateContractGroupId;
        delete postData.generateContractGroupInfo;
      } else if (this.selectedGroupOptVal === "select-group") {
        postData.generateContractGroupId = this.selectedGroup.id;
        delete postData.generateContractGroupInfo;
      } else if (this.selectedGroupOptVal === "create-new") {
        delete postData.generateContractGroupId;
      }
      // 住所の全角変換
      postData.addressInfo.address = this.toFullWidthStringForAddressInfo(postData.addressInfo.address);
      postData.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(postData.addressInfo.buildingName);
      if (postData.invoiceAddressInfo) {
        postData.invoiceAddressInfo.address = this.toFullWidthStringForAddressInfo(postData.invoiceAddressInfo.address);
        postData.invoiceAddressInfo.buildingName = this.toFullWidthStringForAddressInfo(postData.invoiceAddressInfo.buildingName);
      }
      // アカウント名の全角変換
      postData.memberName = this.toFullWidthString(postData.memberName);

      this.processing = true; // 2重submit防止
      axios
        .put(
          API_URL.GENERATOR.EDIT_CONTRACT + this.generateContractId,
          postData
        )
        .then(() => {
          // ステップ２へ
          if (this.$route.query.from === "step2-input") {
            this.$router.push(`/generator/contract/step2/input?generateContractId=${this.generateContractId}`);
          } else {
            this.$router.push(`/generator/contract/step2/edit/${this.generateContractId}`);
          }
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isDisplayNextModal = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    // 県をIDに変換
    changePrefectureToId() {
      return new Promise((resolve) => {
        this.prefectures.map((prefecture) => {
          if (prefecture.name === this.formEdit.addressInfo.prefecturesName) {
            this.formEdit.addressInfo.prefecturesId = prefecture.id;
          }
          if (
            prefecture.name === this.formEdit.invoiceAddressInfo.prefecturesName
          ) {
            this.formEdit.invoiceAddressInfo.prefecturesId = prefecture.id;
          }
        });

        resolve();
      });
    },

    // 次へ押下
    async checkRequiredHandler() {
      this.errMsgs = [];

      const isValid = await this.runValidate(this.formEdit, this.selectedGroupOptVal);
      if (!isValid) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        this.isDisplayNextModal = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      // 都道府県のIDを適用してからPUT
      this.changePrefectureToId().then(() => {
        this.postForm();
      });
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.push(`/generator/contract/${this.$route.params.id}`);
    },

    getApis() {
      return new Promise((resolve) => {
        this.getPrefecturesApi();
        this.getContractGroupListApi();
        this.getLegalPersonalityTypesApi();
        this.getContractTypesApi();
        this.getInvoiceMethodTypeListApi();
        this.getInvoiceTypesApi();

        resolve();
      });
    },
  },

  watch: {
    selectedAddress() {
      if (this.isCompanyInfo) {
        this.formEdit.addressInfo.prefecturesName = this.selectedAddress.address1;
        this.formEdit.addressInfo.address = this.selectedAddress.address2;
        this.formEdit.addressInfo.buildingName = this.selectedAddress.address3;
      } else {
        this.formEdit.invoiceAddressInfo.prefecturesName = this.selectedAddress.address1;
        this.formEdit.invoiceAddressInfo.address = this.selectedAddress.address2;
        this.formEdit.invoiceAddressInfo.buildingName = this.selectedAddress.address3;
      }
    },
  },

  mounted() {
    this.generateContractId = Number(this.$route.params.id);

    this.getApis().then(() => {
      this.getGeneratorContractInfoApi(this.generateContractId).then(() => {
        if (this.formEdit.generateContractGroupId) {
          this.selectedGroupOptVal = "select-group";
          this.selectedGroup = this.contractGroupList.filter(
            (group) => group.id === this.formEdit.generateContractGroupId
          )[0];
        } else {
          this.selectedGroupOptVal = "none";
        }
      });
    });
  },
};
</script>
